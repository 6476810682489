import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BrickRichText from "../components/bricks/rich-text";
import { useI18next } from "gatsby-plugin-react-i18next";
import { getAlternateLang } from "../utils/alternate-lang";
import { Container } from "@mui/material";
import { gtmPushUserInfo } from "../services/auth";

export const query = graphql`
  query TermsOfUse($language: String!) {
    strapiTermsOfUseEn: strapiTermsOfUse(locale: { eq: "en" }) {
      Content {
        richText
      }
    }
    strapiTermsOfUseEs: strapiTermsOfUse(locale: { eq: "es-PA" }) {
      Content {
        richText
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const TermsOfUse = ({ data }) => {
  const { t, language } = useI18next();

  useEffect(() => {
    window.dataLayer?.push({
      event: "page_info",
      page_language: language,
      page_category: "Terms_of_Use",
      page_ta: "",
      page_type: "",
    });
    gtmPushUserInfo();
  }, [language]);

  return (
    <Layout>
      <SEO
        seo={{
          metaTitle: t("nav.termsOfUse"),
          metaDescription: t("nav.termsOfUse").toLocaleLowerCase(),
          linkAlternateHref: "/" + getAlternateLang(language) + "/term-of-use",
          linkHrefLang: getAlternateLang(language),
          noindex: true,
        }}
      />
      <Container
        style={{ paddingLeft: "16px", paddingRight: "16px" }}
        maxWidth={"lg"}
      >
        <BrickRichText
          text={
            language === "en"
              ? data.strapiTermsOfUseEn.Content.richText
              : data.strapiTermsOfUseEs.Content.richText
          }
        />
      </Container>
    </Layout>
  );
};

export default TermsOfUse;
